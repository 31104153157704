/* Dashboard  Home Styles ** */
.dashboard-container {
  background: var(--white-color);
}

.dashboard-container section {
  /* border:1px solid red; */
  margin-bottom: 0;
}

.dashboard-container .form-field input {
  border: 1px solid var(--frame-color);
  color: var(--dark-color);
}

.dashboard-container h4,
.dashboard-container h3 {
  color: var(--primary-color);
  /* font-size:1.4rem; */
  margin-bottom: 0.5rem;
}

.dashboard-container .verified {
  background: rgba(107, 248, 147, 0.27);
  border-radius: 7px;
  color: var(--green-color);
  padding: 0.15rem 0.75rem;
  font-size: 0.875rem;
}
.dashboard-container .unverified {
  background: rgba(229, 62, 62,0.25);
  border-radius: 7px;
  color: var(--dark-color);
  padding: 0.15rem 0.75rem;
  font-size: 0.875rem;
}
/* .dashboard-container h5{
 color:var(--primary-color);
 font-size:;
} */
.dashboard-container h5 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  color: var(--dark-color);
}
.dashboard-container h5.title {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
}

.dashboard-container p.title {
  background: var(--frame-color);
  color: var(--dark-color);
  border-radius: 4px;
  display: inline-block;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  /* text-transform:uppercase; */
}
.dashboard-container .message-box {
  border: 1px solid var(--frame-color);
  padding: 2rem 1.2rem;
  border-radius: 8px;
  display: flex;
}
.dashboard-container .message-box .message-contents {
  flex-basis: 94%;
  margin: auto;
}
.dashboard-container button.submit:disabled {
  background: var(--frame-color);
}
.dashboard-container button.submit{
  background: var(--primary-color);
  color:var(--white-color);
}
.dashboard-container button.submit:hover svg{
fill:none;
}
.dashboard-container .dropdown{
background:var(--white-color);
box-shadow:2px 3px 4px rgba(0, 0, 0, 0.25);
padding:0.5rem;
}

/* ***************************
CTA Buttons 
==============================
*/
.dashboard-container .cta-btn {
  border-radius: 4px;
  background: var(--white-color);
  border: 1px solid var(--frame-color);
  margin-right: 1rem;
  padding: 0.6rem 1rem;
}

.dashboard-container .dashboard-main-body .cta-btn{
  /* font-size:80%; */
  padding:0.75rem;
  margin-top:2.4rem;
}
.dashboard-container .cta-btn.active {
  background: var(--primary-color);
  border: 1px solid transparent;
  color: var(--white-color);
}
.dashboard-container .cta-btn.active svg {
  fill: #fff;
}
.dashboard-container .cta-btn:hover {
  background: var(--primary-color);
  border: 1px solid transparent;
  color: #fff;
}
.dashboard-container .cta-btn:hover svg {
  fill: #fff;
}



/* ********************************
PROFILE UPDATE STYLES
====================================*/
.profile-update{
display:flex;
min-height:100vh;
}
.profile-update .profile-wrapper{
margin:auto;
}
.profile-update .img-container{
border:1px dashed var(--frame-color);
text-align:center;
height:150px;
width:150px;
overflow:hidden;
line-height:150px;
border-radius:50%;
}
.profile-update .cta-btn{
background:var(--primary-color);
color:var(--white-color);
border-radius:4px;
}
.profile-update .img-container span{
vertical-align:middle;
}






/* ********************************
My WALLET PAGE STYLES
====================================*/
.dashboard-container .account-info .icon-container{
  width:45px;
  height:45px;
  background: rgba(77, 124, 255, 0.08);
  border-radius:50%;
  padding:0.6rem;
  margin-right:1rem;
}
.dashboard-container .account-info .icon-container img{
border-radius:initial;
}
.dashboard-container .account-info .grid-item.border-item{
border:1px solid var(--frame-color);
padding:1rem;
border-radius:8px;
}
.dashboard-container .account-info h5.title{
margin-bottom:0.875rem;
font-weight:600;
text-transform:uppercase;
}
.dashboard-container .account-info h6{
color: var(--light-blue);
}
.dashboard-container .account-info p{
color: var(--grey-text);
}
/* .dashboard-container .account-info .btn-contianers {
display:flex;
} */
.dashboard-container .account-info .btn-contianers .cta-btn{
margin-bottom:1.2rem;
}






/* ********************************
Fund Wallet  and Withdraw wallet
====================================*/

/* *********WITHDRAW WALLET************** */
.dashboard-container .fundwallet-container.withdraw-assets .trade-chart{
max-width:auto;
}
.dashboard-container .fundwallet-container.withdraw-assets .tabular-data{
width:100%;
}



.dashboard-container .fundwallet-container .trade-chart {
  margin: 2rem 0;
  padding: 0rem;
  max-width: 850px;
  overflow: auto;
}
.dashboard-container .fundwallet-container .trade-chart::-webkit-scrollbar {
  background: transparent;
  border-radius: 6px;
  width: 10px;
  height: 6px;
}
.dashboard-container
  .fundwallet-container
  .trade-chart::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 2px;
}

.dashboard-container .fundwallet-container .tabular-data {
  width: 1200px;
  border-collapse: collapse;
}
.dashboard-container .fundwallet-container .table-data,
.dashboard-container .fundwallet-container .table-head {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: left;
  padding: 24px 24px;
  border-bottom: 1px solid #e8eaf0;
  vertical-align: middle;
  color: #000;
}
.dashboard-container .fundwallet-container .table-head {
  font-size: 0.875rem;
  font-weight: 400;
}
.dashboard-container .fundwallet-container .currency-symbol {
  display: flex;
  gap: 4%;
  position: relative;
  align-items: center;
}
.dashboard-container .fundwallet-container .first-symbol {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 0px solid #fff;
  position: relative;
  overflow: hidden;
}
.dashboard-container .fundwallet-container tr td:last-child span{
color:var(--primary-color);
cursor: pointer;
font-weight:600;
font-size:1rem;
color:var(--white-color);
}





/* ************************************
WALLET ASSETS STYLES
======================================= */
.dashboard-container .assets-section {
  box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.45);
  -o-box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.45);
  border-radius: 13px;
  padding: 1rem;
  margin-bottom: 1.4rem;
}
.dashboard-container .assets-section .flex-container figure {
  padding: 1rem;
  text-align: center;
}
.dashboard-container .assets-section .flex-container figure:not(:last-child) {
  border-bottom: 1px solid var(--frame-color);
  border-radius: 16px;
}
.dashboard-container .assets-section .flex-container > *:not(:last-child) {
  transition: 0.5s ease;
  margin-bottom: 2rem;
}
.dashboard-container .assets-section .flex-container > *:hover {
  background: var(--primary-color);
  box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  color: var(--white-color);
  cursor: pointer;
}

.dashboard-container .assets-section .flex-container .item-header {
  display: flex;
  flex-flow: row wrap;
  gap: 4%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.dashboard-container .assets-section .flex-container figure p {
  /* color:#000000; */
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 39px;
}
.dashboard-container
  .assets-section
  .flex-container
  .item-header
  .img-container {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
}
.dashboard-container .assets-section .flex-container .item-header span {
  font-weight: 500;
  font-size: 0.8rem;
}

.dashboard-container
  .assets-section
  .flex-container
  .item-header
  .img-container
  img {
  height: 100%;
}

/* ****REFERRAL PAGE */
.dashboard-container h4.title {
  font-size: 1.2rem;
}
.dashboard-container .invest- .why-invest ol,
.dashboard-container .referral ol {
  list-style: number;
  padding: initial;
  list-style-position: inside;
}
.dashboard-container .invest-container .why-invest ul,
.dashboard-container .referral ul {
  list-style: initial;
  padding: initial;
  list-style-position: inside;
}
.dashboard-container .invest-container .why-invest li,
.dashboard-container .referral li {
  margin-bottom: 0.875rem;
}
.dashboard-container .referral .ref-link input {
  width: initial;
  display: inline-block;
  font-size: 0.875rem;
  background: var(--grey-color);
  color: #fff;
  padding: 0.65rem 0.5rem;
}
.dashboard-container .referral .ref-link .copied {
  color: var(--green-color);
}
.dashboard-container .referral .ref-link .copy-icon {
  font-size: 1rem;
}

/* *****WITHDRAWAL PAGE********** */
.dashboard-container .cta-btn.chat-support {
  box-shadow: 2px 2px 3px rgb(43, 108, 176);
  width: 100%;
}

/* *************
SIDE MENU 
*********/
.dashboard-container .aside {
  /* border:3px solid yellow; */
  /* position: relative; */
  /* position: absolute;
top:0;
left:0;

min-height:100vh;
z-index:1000;
background:rgba(0,0,0,0.25); */
  /* opacity:0; */
  position: relative;
  position: absolute;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  transform: translateX(-150%);
  height: 100vh;
  overflow: auto;
  z-index: 1030;
}
.dashboard-container .aside.active {
  transform: translateX(0);
  transition: 0.5s ease-in-out;
}
.dashboard-container .aside::-webkit-scrollbar {
  height: 5px;
  width: 6px;
}
.dashboard-container .aside::-webkit-scrollbar-thumb {
  background: var(--teal-color);
  border-radius: 2px;
}
.dashboard-container .side-menu {
  background: var(--deep-blue);
  margin-bottom: 0;
  height: 120vh;
  width: 255px;
}
.dashboard-container .side-menu a {
  font-weight: 600;
}
.side-menu .profile-img-container {
  width: 58px;
  height: 58px;
  border-radius:50%;
}
.side-menu h5 {
  color: var(--frame-color);
}

/* **Close Side Menu */
.dashboard-container .close-side-menu.active {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1020;
  background: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

/* .dashboard-container .side-menu a,
.dashboard-container .side-menu svg{
color:var(--frame-color);
fill:var(--frame-color);
} */
.dashboard-container .side-menu a:hover,
.dashboard-container .side-menu a:focus-within,
.dashboard-container .side-menu a:hover svg,
.dashboard-container .side-menu a:focus-within svg,
.dashboard-container .side-menu a.active svg,
.dashboard-container .side-menu a.active svg {
  color: var(--white-color);
  fill: var(--white-color);
  background: var(--dark-color);
}

.dashboard-container .grid-2 > * {
  /* position: relative; */
  margin-bottom: 0;
}
.dashboard-container .grid-2 > :last-child {
  /* position: relative; */
  /* border: 1px solid red; */
}

/* ****
====================================
Dashboard - INVESTMENT DETAILS
*/
.dashboard-container .investment-details .grid-item {
  margin-bottom: 2rem;
}
.dashboard-container .investment-details .grid-item h5 {
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.dashboard-container .investment-details .flex-item:last-child {
  border: 1px solid var(--frame-color);
  border-radius: 8px;
  padding: 1rem;
}
.dashboard-container .form-container .double {
  flex-flow: column wrap;
  align-items: flex-start;
}
.dashboard-container .form-container .double > * {
  flex-basis: auto;
}

.dashboard-container .investment-details input[type="radio"] {
  width: auto;
  transform: scale(1.5);
}
.dashboard-container .investment-details input[type="radio"] + label {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

/* ****
====================================
Dashboard - TABLE STYLES
*/
.dashboard-container .table-container{
max-width:850px;
overflow:auto;
}
.dashboard-container .table-element {
  width: 1200px;
  border-collapse: collapse;
}
.dashboard-container .table-container::-webkit-scrollbar {
  background: transparent;
  border-radius: 6px;
  width: 10px;
  height: 6px;
}
.dashboard-container .table-container::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 2px;
}
.dashboard-container .table-element,
.dashboard-container .table-element td,
.dashboard-container .table-element th {
  padding: 1.2rem 0.875rem;
  border-bottom: 1px solid var(--frame-color);
  text-transform: capitalize;
  text-align: left;
}

.profile-container .form-container input {
  border: 1px solid var(--frame-color);
}
.dashboard-container .modal-container button.submit,
.profile-container .form-container button {
  background: var(--primary-color);
  color: var(--white-color);
  font-weight: 600;
  border-radius: 4px;
}
.dashboard-container .modal-container button.submit:disabled,
.profile-container .form-container button:disabled {
  background: var(--frame-color);
}

/* ************************ 
PACKAGE PLAN STYLES
============================
*/
.dashboard-container .affordable-packages .card-container{
padding:0;
}
.dashboard-container .affordable-packages .card:hover {
  border: 2px solid var(--light-blue);
}
.dashboard-container .selected-package {
  display: inline-block;
  padding: 0.5rem 0.875rem;
  color: var(--white-color);
  border-radius: 14px;
  font-size: 1rem;
  vertical-align: middle;
  cursor: pointer;
  text-transform: uppercase;
}



/* ***************
MEDIUM SCREENS
========================= */
@media (min-width: 768px) {
  /*WALLET ASSETS  */
  .dashboard-container .assets-section .flex-container figure + figure {
    border-left: 1px solid var(--frame-color);
  }
  .dashboard-container .assets-section .flex-container figure:not(:last-child) {
    border-bottom: 1px solid transparent;
    border-radius: 0;
  }
  .dashboard-container .assets-section .flex-container figure:hover {
    border-radius: 16px;
  }
  .dashboard-container .assets-section .flex-container figure:hover + figure {
    border-left: 1px solid transparent;
  }
  .dashboard-container .assets-section .flex-container > *:not(:last-child) {
    margin-bottom: auto;
  }
  .dashboard-container .form-container .double {
    flex-flow: row wrap;
  }
  .dashboard-container .form-container .double > * {
    flex-basis: 48%;
  }
  .dashboard-container .fundwallet-container .tabular-data {
    width: 100%;
  }
  .dashboard-container .fundwallet-container .trade-chart {
    overflow: hidden;
    max-width: 100%;
  }

  /* TABLE STYLES */
  .dashboard-container .table-element{
   width:100%;
  }
  .dashboard-container .withdraw-history-conatiner .table-element{
   width:1200px;
  }
}

/*=====================================
Desktop  Desktop
=======================================*/
@media (min-width: 1200px) {
  .dashboard-container h4.title {
    font-size: 1.8rem;
  }
  .dashboard-container .grid-2 {
    display: grid;
    /* grid-template-columns: repeat(1, fit-content(251px) 1fr); */
    grid-template-columns: minmax(0, 251px) 1fr;
  }

  /* **SIDE MENU */
  .dashboard-container .aside {
    background: rgba(0, 0, 0, 0.25);
    transition: initial;
    transform: translateX(0);
    margin-top: 4.8rem;
  }
  .dashboard-container .side-menu {
    height: 140vh;
  }
  .dashboard-container .cta-btn {
    margin-left: 1rem;
    margin-right: auto;
  }
}
