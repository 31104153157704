/**************footer-container styles for every page
  *******************/
.footer-container {
  /* background-color: #fff; */
  background-color:var(--footer-bg);
}
.footer-container nav {
  margin-top: 2rem;
  padding: 1rem;
}
.footer-container nav ul {
  /* font-weight:bold; */
  line-height: 1.5;
}
.footer-container nav ul a {
  color: var(--white-color);
  font-weight: 600;
  font-size:var(--md-font);
}
.footer-container nav ul ul a {
  font-size: var(--sm-font);
  color: #a7a7a7;
  font-weight: 400;
  padding:0.5rem;
}
.footer-container nav > ul > li {
  margin-bottom: 2rem;
}
.footer-container nav ul.social-icons div {
  border: 0px solid green;
  display: flex;
  flex-flow: row wrap;
  gap: 4%;
}
.footer-container nav ul.social-icons div > * {
  flex-basis: 8%;
}
.footer-container .copy-right{
text-align:center;
padding:2rem 1rem;
border-top:1px solid;
width:100%;
margin:0 auto;
}
.footer-container .location-text svg{
vertical-align:middle;
}

/******************
        tablet device
      *******************/
@media (min-width: 380px) {
  .footer-container nav {
    padding: 2rem;
  }
  .footer-container nav ul {
    columns: 2;
  }
  .footer-container nav ul ul {
    columns: 1;
  }
}



/* ****************
Privacy Policy
*******************/
.privacy-policy ul{
list-style:disc;
list-style-position: inside;
}
.terms-and-conditions p ,
.privacy-policy p {
margin-bottom:2.4rem;
padding:1rem;
}




/******************
        desktop device
      *******************/
@media (min-width: 810px) {
  .footer-container nav {
    /* border:2px solid green; */
    margin: 0;
    padding: 1rem;
  }
  .footer-container nav ul {
    display: flex;
    flex-flow: row wrap;
    /*justify-content:space-between;*/
    gap: 4%;
  }
  .footer-container nav ul > * {
    flex-basis: 22%;
  }
  .footer-container nav ul ul {
    display: block;
  }
}
