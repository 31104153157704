/* MODAL STYLES */
/* .modal-container {
  border: 1px solid red;
}
.modal-container .modal-overlay {
  border: 1px solid yellow;
} */
.modal-container,
.modal-container .modal-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index:1040;
}
.modal-overlay {
  background:rgba(43, 108, 176,0.75);
}

.modal-body label.title{
font-size:0.85rem;
color:var(--dark-color);
font-weight:600;
}

.modal-content {
  position: absolute;
  top: 100%;
  left: 50%;
-moz-transform:translate(-50%,-50%);
-webkit-transform:translate(-50%,-50%);
-o-transform:translate(-50%,-50%);
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background:var(--white-color);
  padding: 1rem;
  position: relative;
  max-width:320px;
  min-height: 351px;
  /* border:1px solid yellow; */
-moz-transition:all 0.1s ease-out;
-webkit-transition:all 0.1s ease-out;
  box-shadow:2px 1px 4px rgba(0,0,0,0.5);
  z-index:1045;
  transition:0.5s ease;
}


.modal-content.show-modal{
 /* border:3px solid green; */
 top:50%;
}
/* .modal-content > *:not(:last-child) {
  margin-bottom: 2rem;
  border:1px solid red;
} */

.modal-header h5.title{
margin-bottom:0.7rem;
}

.modal-header p.title{
  font-size:0.65rem;
  color:var(--red-color);
  font-style:italic;
  }

.modal-body {
  margin-bottom: 0;
}
.modal-body .qr-code{
text-align:center;
}

.modal-body .wallet-address{
word-break:break-all;
font-size:0.875rem;
}
.modal-body .qr-code .img{
/* width:100%; */
}

.modal-body input {
  border: 2px solid #0c8ce9;
}

.modal-body .display-asset-conversion p{
font-size:1rem;
color: var(--dark-color);
font-weight:500;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}



/* 
======================
Tablet Device 
======================
*/
@media (min-width: 710px) {
  .modal-content {
    max-width: 421px;
  }
}

/* Desktop Device */
@media (min-width: 980px) {
  /* .modal-content {
    top: 50%;
  } */
}
