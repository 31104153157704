

/* ADMIN STYLES */
/* .admin-dashboard{
border:1px solid yellow;
} */
 .admin-dashboard  .cta-btn{
background:var(--frame-color);
border-radius:8px;
padding:0.5rem;
margin:1rem auto;
font-weight:600;
}

.admin-dashboard  .cta-btn.active{
background:var(--primary-color);
color:var(--white-color)
}

.admin-dashboard .update-user-kyc .cta-btn{
background:var(--primary-color);
color:var(--white-color);
}

.admin-dashboard .user-card{
margin:3rem auto;
}
.admin-dashboard .user-card .img-container{
width:95px;
height:95px;
border-radius:50%;
border:1px solid var(--frame-color);
padding:0.5rem;
}

.admin-dashboard .update-user-info > div{
/* border:1px solid red; */
display:inline-block;
margin-right:0.5rem;
}
.admin-dashboard .verified {
  background: rgba(107, 248, 147, 0.55);
  border-radius: 7px;
  color: var(--frame-color);
  padding: 0.15rem 0.75rem;
  font-size: 0.875rem;
}
.admin-dashboard .unverified {
  background: rgba(229, 62, 62,0.55);
  border-radius: 7px;
  color: var(--frame-color);
  padding: 0.15rem 0.75rem;
  font-size: 0.875rem;
}