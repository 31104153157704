

/* Accordion Styles */
.accordion-item {
    border: 1px solid var(--frame-color);
    margin-bottom: 1rem;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .accordion-item .accordion-header {
    background:var(--light-grey);
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
    text-align:  initial;
    color:var(--dark-color);
  }
  
  .accordion-header:hover {
    background: #e2e2e2;
    background:var(--primary-color);
    color:var(--white-color);
  }
  
  .accordion-content {
    padding: 10px;
    background-color: var(--white-color);
    border-top: 1px solid #ccc;
  }
  