

/* AUTH Styles */
.auth-section{
/* border:1px solid red; */
background:var(--white-bg);
min-height:100vh;
}
.auth-section label.remember-me{
/* display:inline-block; */
}
.auth-section label.remember-me input{
/* outline:1px solid red; */
width:auto;
margin-right:10px;
}
.auth-section .img-container{
width:98px;
margin:2rem auto;
}
.auth-section .form-body{
display:flex;
}
.auth-section .form-body >*{
margin:auto;
flex-basis:100%;
}
.auth-section .form-field input,
.auth-section .form-field select{
border:1px solid var(--frame-color);
background:#fff;
}
.auth-section .form-field select {
-webkit-appearance: none; /* For Safari */
-moz-appearance: none; /* For Firefox */
}
.auth-section .form-field .select-field {
  position: relative;
  z-index:1000;
}
.auth-section .form-field .select-field:after {
  position: absolute;
  content: "<>";
  transform: rotate(90deg);
  font-size: 16px;
  color: #a7a7a7;
  top: 20px;
  right: 10px;
  z-index:998;
}
/* .auth-section .form-field .select-field::-w */
.auth-section .form-field button,
.auth-section .form-field .auth-cta-btn{
width:100%;
background:var(--primary-btn);
color:#fff;
font-size:1rem;
border-radius:4px;
display:inline-block;
margin:0.5rem auto;
/* font-weight:; */
text-align:center;
}
/* when any button is disabled */
.contactus-section .form-field button:disabled,
.auth-section .form-field button:disabled{
background:var(--frame-color);
}
.auth-section .form-field button span{
/* border:1px solid red; */
}
.auth-section .option-label{
text-align:center;
position: relative;
}
.auth-section .option-label::before,
.auth-section .option-label::after{
content: "";
width:40%;
border:1px solid var(--frame-color);
display:inline-block;
vertical-align:middle;
margin-right:10px;
}
.auth-section .option-label::after{
margin-left:10px;
}
.auth-section input[type="number"]{
border-radius:0 4px 4px 0;
}
.auth-section .dail-code{
border-width:1px 0 1px 1px;
border-style: solid;
border-color:var(--frame-color);
border-radius:4px 0 0 4px;
}

/*
============================
Password Reset Token Styles
============================
*/
.auth-section .otp-field {
  display: flex;
  gap: 10px;
  margin: 20px auto;
}
.auth-section .otp-field input {
  text-align: center;
  padding: 0.7rem;
  font-weight: 600;
  font-size: 1.2rem;
}
.auth-section .otp-field input:focus {
  border: 2px solid var(--primary-btn);
}



/* 

*************MEDIA QUERIES**********
*/

/* 
=====================================
Desktop Device
=====================================
*/
@media(min-width:980px){
.auth-section .form-body >*{
flex-basis:48%;
}
}