

/* *****Home Styles */
.hero-section{
position: relative;
z-index:1000;
height: 100vh;
display:flex;
transition:background 1.5s ease-in-out;
}
.hero-section .cta-btn{
border-radius:8px;
font-size:1.25rem;
font-weight:700;
}

.hero-section h2{
/* color:#1e90ff; */
color:#fff;
}
.hero-section h4{
 color:#a9a9a9;   
}
.hero-section p{
color:#f8f8f8;
}
.hero-section::before{
content:"";
position: absolute;
top:0;
left:0;
display:inline-block;
width:100%;
height:100%;
background:rgba(0,0,0,0.75);
z-index:-1;
}
.hero-section .btn-container{
margin-top:20px;
text-align:initial;
}
.hero-section .strong-text{
font-weight:800;
font-size:3.5rem;
}
.hero-section-wrapper{
 margin:auto auto auto 0;
}



/* ***Upward Counter */
.upward-counter{
/* border:1px solid red; */
}
.upward-counter .grid-item:not(:last-child){
margin-bottom:2.8rem;
}
.upward-counter .grid-item p{
font-size:1.2rem;
font-weight:600;
}


/* **WHy US */
.whyus{
/* border:1px solid red; */
}
.whyus .item{
text-align:center;
margin:2rem 0;
}
.whyus h4{
border-radius:50%;
width:100px;
height:100px;
line-height:100px;
background:var(--deep-blue);
transition:width 0.5s ease, height 0.5s ease,line-height 0.5s ease;
cursor: pointer;
}
.whyus h4:hover{
width:120px;
height:120px;
line-height:120px;
background:var(--secondary-color);
color:var(--dark-color);
font-size:2rem;
}
.whyus h4:hover + p{
color:var(--white-color);
font-size:1rem;
font-weight:600;
}

/* ***Affordable Packages */
.affordable-packages .card-container{
padding:1rem;
}
.affordable-packages .card{
background:var(--deep-blue);
color:var(--frame-color);
border-radius:8px;
padding:1.2rem;
box-shadow:2px 2px 4px rgba(0,0,0,0.15);
cursor: pointer;
border:1px solid transparent;
transition:0.5s ease;
/* display:inline-block; */
}
.affordable-packages .card h4,
.affordable-packages .card .profit{
font-weight:600;
text-transform:uppercase;
color:var(--white-color);
}
.affordable-packages .card .cta-btn{
background:transparent;
border:1px solid var(--grey-color);
color: var(--white-color);
margin:1rem auto;
transition:0.5s ease;
}
.affordable-packages .card:hover{
border:1px solid var(--grey-color);
}
.affordable-packages .card:hover .cta-btn{
background:var(--light-blue);
border:transparent;
}
.affordable-packages .card li{
text-transform:capitalize;
margin-bottom:1rem;
font-weight:400;
}
.affordable-packages .check-icon,
.affordable-packages .close-icon{
display:inline-block;
vertical-align:middle;
}
.affordable-packages .check-icon svg{
fill:var(--light-blue);
}
.affordable-packages .close-icon svg{
fill:var(--red-color);
}


/* *********How to Invest */
.fund-wallet{
background:var(--frame-color);
}
.fund-wallet h3{
color:var(--primary-color)
}

.fund-wallet ul{
padding-left:2.8rem;
}
.fund-wallet ul li{
/* border:1px solid blue; */
padding:1rem;
font-weight:400;
text-transform:capitalize;
position: relative;
}
.fund-wallet ul li::before{
content:url("../../public/assets/check-icon.svg");
position:absolute;
display:inline-block;
top:20%;
right:100%;
}
.fund-wallet ul li+li::after{
content:"...";
display:inline-block;
position:absolute;
transform:rotate(90deg);
left:-15px;
top:-25px;
font-size:2rem;
color:var(--light-blue);
line-height:1.5;
}




/***Testimonials Card */
.testimonials .card{
background: #0B0C0D;
padding:1rem;
border-radius:12px;
margin-left:20px;
min-height:280px;
}



/* ***Services */
.card-container{
/* box-shadow:2px 2px 4px rgba(0,0,0,0.15);
display:inline-block;
padding:1rem;
margin-bottom:2.4rem; */
}

.card-container .icon-container{
 height:50px;
 width:50px;
 background:#fff;
 border-radius:50%;
 margin-bottom:1.4rem;
 box-shadow:1px 2px 4px rgba(0,0,0,0.25);
 display:flex;
 align-items:center;
 justify-content:center;
}
.card-container .icon-container span{
/* border:1px solid blue; */
}



/* ***Products */
.products .product-container a{
padding:0;
border-radius:8px;
}
.products .product-container .img-container{
border-radius:8px 8px 0 0; 
height:400px;
width:100%;
}
.product-container .bottom-section{
padding:1.4rem;
text-align:right;
}
.product-container .bottom-section p{
margin-top:2rem;
padding:0.7rem;
display:inline-block;
border-radius:8px;
background:#000088;
color:#fff;
}



/* ***Launching Styles */
.launching-soon{
/* border:1px solid red; */
}
.launching-soon .launching-container {
background:var(--primary-color);
padding:2.4rem 1.4rem;
border-radius:12px;
color:var(--white-color);
}
.launching-soon .flex-container{
display:flex;
align-items:center;
justify-content:flex-end;
flex-flow:row wrap;
gap:4%;
}
.launching-soon .flex-container>*{
cursor: pointer;
flex-basis:32%;
}
.launching-soon .img-container{
display:inline-block;

}



/* *****************************
 Desktop Device
*******************************/
@media(min-width:980px){
.upward-counter .grid-item:not(:last-child){
margin-bottom:initial;
}
}