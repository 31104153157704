/* Header Styles */
/* ****HEADER STYLES */
.dashboard-container .dashboard-header {
  position:fixed;
  width:100%;
  top:0;
  left:0;
  background:var(--white-color);
  padding:14px 24px;
  z-index:1020;
  /* border:1px solid red; */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.125);
}
.dashboard-container .flex-wrapper{
/* align-items:flex-end; */
}
.dashboard-container .flex-wrapper>*{
/* border:1px solid red; */
}
.dashboard-header .profile-img-container{
  height: 45px;
  width: 45px;
}
.dashboard-container .logo-container{
  padding:0;
  height:54px;
  width: 54px;
}
.dashboard-header .profile-img-container {
  border: 1px solid var(--frame-color);
  border-radius: 50%;
}
.dashboard-header .notification-icon svg {
  fill: var(--deep-blue);
}
.dashboard-header .notification-icon {
  position: relative;
  display: block;
  cursor: pointer;
}
.dashboard-header .notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--red-color);
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: var(--white-color);
  text-align: center;
  font-size: 0.75rem;
}








/* **********************************
 Desktop Device
************************************/
@media(min-width:1200px){
/* Hide the MenuBar Icon */
.dashboard-header .icon-container{
display:none;
}
}
